<template>
  <div class="dashboard-org">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Manage Notifications</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Notifications</v-toolbar>
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="ml-auto mr-3"
                  dark
                  rounded
                  @click="dialog = true"
                >
                  + Add notification
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :headers="headers"
                :disable-sort="true"
                :items="items"
                single-expand
                :options.sync="options"
                :server-items-length="itemsTotal"
                show-expand
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPageOptions: [10]
                }"
              >
                <template v-slot:expanded-item="{ item }">
                  <td colspan="4">
                    <div class="my-5 ml-5" v-html="item.body" />
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
    <v-dialog v-model="dialog" width="1000">
      <v-card>
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    filled
                    v-model="editedItem.title"
                    label="Title"
                    :rules="[v => !!v || 'Title is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.message"
                    label="Message"
                    :value="editedItem.message"
                    :rules="[v => !!v || 'Message is required']"
                  ></v-text-field>
                  <TiptapVuetify
                    v-model="editedItem.body"
                    :extensions="extensions"
                    
                  >
                  </TiptapVuetify>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="back-to-blue mb-3 ml-5" rounded @click="dialog = false">
            cancel
          </v-btn>
          <v-btn
            class="mb-3 mr-3 ms-auto"
            color="secondary"
            rounded
            @click="saveItem(editedItem)"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import { Role } from "@/helpers/roles";
import AnnouncementsService from "../services/announcements.service";
//import Tiptap from "@/components/TipTap";
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "ManageAnnouncements",
  components: {
    Header,
    TiptapVuetify
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      // starting editor's content
      content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,
      dialog: false,
      search: "",
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Manage Notifications",
          disabled: true,
          href: "/announcements"
        }
      ],
      selectedItems: [],
      editedItem: {
        name: "",
        description: ""
      },
      originalItem: {
        name: "",
        description: ""
      },
      defaultlItem: {
        name: "",
        description: ""
      },
      items: [],
      isLoading: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      headers: [
        { text: "Title", value: "title" },
        { text: "Message", value: "message" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      editedIndex: -1
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getItems();
        }
      },
      deep: true
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    formTitle: {
      get() {
        return this.editedIndex === -1
          ? "Create New Notification"
          : "Edit Notification";
      },
      set(newTitle) {
        return newTitle;
      }
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  methods: {
    // async deleteSelectedItems() {
    //   this.deleteModal = false;

    //   this.selectedItems.forEach(item => {
    //     CoursesService.removeCategory({
    //       id: item.id
    //     }).then(() => {
    //       this.categories = this.categories.filter(cat => cat.id !== item.id);
    //     });
    //   });
    //   this.selectedItems = [];
    //   this.showDeletedStatus = true;
    // },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getItems();
    },

    async getItems() {
      this.dataLoading = true;

      const { page } = this.options;

      const response = await AnnouncementsService.get({
        query: {
          page
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.items = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.dataLoading = false;
    },

    editItem(item) {
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        // if (this.editedIndex > -1) {
        //   // update item
        //   const data = {
        //     title: item.title,
        //     message: item.message,
        //     body: item.body
        //   };

        //   // const response = await AnnouncementsService.update({
        //   //   id: item.id,
        //   //   data: data
        //   // });
        //   // this.items.splice(this.editedIndex, 1, response.data.data);
        //   this.showSnackbar("The Announcement has been updated successfully");
        // } else {
        // create item

        const data = {
          title: item.title,
          message: item.message,
          body: item.body
        };

        const response = await AnnouncementsService.create({
          data: data
        });

        this.items.push(response.data.data);
        this.showSnackbar("The Announcement has been added successfully");
      }
      //}
      this.dialog = false;
      this.$refs.form.reset();
    }
  }
};
</script>
<style lang="scss">
.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
// .tiptap-vuetify-editor__action-render-btn {
//   margin: 2px 6px;
// }
// .tiptap-vuetify-editor__btn-icon.v-icon {
//   font-size: 16px;
// }
// .tiptap-vuetify-editor__toolbar .v-toolbar {
//   display: flex;
//   height: auto !important;
//   padding: 5px;
// }
// .tiptap-vuetify-editor__toolbar .v-toolbar .v-toolbar__content {
//   height: auto !important;
//   flex-wrap: wrap;
//   padding: 0;
// }
// .tiptap-vuetify-editor-pop {
//   position: fixed;
//   z-index: 1;
//   opacity: 0.9 !important;
//   transition: transform 0.1s ease-in-out;
// }
// .tiptap-vuetify-editor-pop .tiptap-vuetify-editor-pop__popper {
//   background-image: linear-gradient(180deg, rgba(49, 49, 47, 0.99), #262625);
//   background-repeat: repeat-x;
//   border-radius: 5px;
//   padding: 0 10px;
//   color: #fff;
//   line-height: 44px;
//   display: inline-block;
//   visibility: hidden;
//   opacity: 0;
//   pointer-events: none;
// }
// .tiptap-vuetify-editor-pop .tiptap-vuetify-editor-pop__popper--visible {
//   visibility: visible;
//   pointer-events: auto;
//   -webkit-animation: pop-upwards 0.18s linear forwards;
//   animation: pop-upwards 0.18s linear forwards;
//   -webkit-animation-delay: 0.2s;
//   animation-delay: 0.2s;
//   transition: opacity 0s linear 0.2s;
// }
// .tiptap-vuetify-editor-pop[x-placement="top"] {
//   margin-bottom: 7px;
// }
// .tiptap-vuetify-editor-pop[x-placement="bottom"] {
//   margin-top: 7px;
// }
// .tiptap-vuetify-editor-pop[x-placement="bottom"]
//   .tiptap-vuetify-editor-pop__popper {
//   background-image: linear-gradient(0deg, rgba(49, 49, 47, 0.99), #262625);
// }
// [x-arrow] {
//   position: fixed;
//   width: 14px;
//   height: 14px;
//   background-color: #262625;
//   transform: rotate(45deg);
//   z-index: -1;
// }
// [x-placement="top"] [x-arrow] {
//   margin-bottom: -7px;
//   bottom: 0;
// }
// [x-placement="bottom"] [x-arrow] {
//   margin-top: -7px;
//   top: 0;
// }
// @-webkit-keyframes pop-upwards {
//   0% {
//     transform: matrix(0.97, 0, 0, 1, 0, 12);
//     opacity: 0;
//   }
//   20% {
//     transform: matrix(0.99, 0, 0, 1, 0, 2);
//     opacity: 0.7;
//   }
//   40% {
//     transform: matrix(1, 0, 0, 1, 0, -1);
//     opacity: 1;
//   }
//   70% {
//     transform: matrix(1, 0, 0, 1, 0, 0);
//     opacity: 1;
//   }
//   to {
//     transform: matrix(1, 0, 0, 1, 0, 0);
//     opacity: 1;
//   }
// }
// @keyframes pop-upwards {
//   0% {
//     transform: matrix(0.97, 0, 0, 1, 0, 12);
//     opacity: 0;
//   }
//   20% {
//     transform: matrix(0.99, 0, 0, 1, 0, 2);
//     opacity: 0.7;
//   }
//   40% {
//     transform: matrix(1, 0, 0, 1, 0, -1);
//     opacity: 1;
//   }
//   70% {
//     transform: matrix(1, 0, 0, 1, 0, 0);
//     opacity: 1;
//   }
//   to {
//     transform: matrix(1, 0, 0, 1, 0, 0);
//     opacity: 1;
//   }
// }
// .tiptap-vuetify-editor {
//   position: relative;
// }
// .tiptap-vuetify-editor .ProseMirror {
//   outline: 0 !important;
//   margin: 20px !important;
// }
// .tiptap-vuetify-editor--disabled {
//   cursor: not-allowed;
// }
// .tiptap-vuetify-editor__content {
//   transition: all 2s;
//   overflow: auto !important;
//   padding: 5px;
// }
// .tiptap-vuetify-editor__content a {
//   pointer-events: none;
// }
// .tiptap-vuetify-editor__content h1,
// .tiptap-vuetify-editor__content h2,
// .tiptap-vuetify-editor__content h3,
// .tiptap-vuetify-editor__content h4 {
//   margin: 10px 0 20px !important;
// }
// .tiptap-vuetify-editor__content blockquote {
//   border-left: 0.25em solid #dfe2e5;
//   color: #6a737d;
//   padding-left: 1em;
//   margin: 20px 0 !important;
// }
// .tiptap-vuetify-editor__content code {
//   padding: 0 4px !important;
//   margin: 0 5px !important;
// }
// .tiptap-vuetify-editor__content pre code {
//   padding: 8px !important;
//   margin: 0 5px !important;
// }
// .tiptap-vuetify-editor__content code:after,
// .tiptap-vuetify-editor__content code:before {
//   content: none !important;
//   letter-spacing: normal !important;
// }
// .tiptap-vuetify-editor__content p {
//   margin-top: 16px !important;
//   margin-bottom: 16px !important;
//   min-height: 1rem;
// }
// .tiptap-vuetify-editor__content
//   p.tiptap-vuetify-editor__paragraph--is-empty:first-child:before {
//   content: attr(data-empty-text);
//   float: left;
//   color: #aaa;
//   pointer-events: none;
//   height: 0;
//   font-style: italic;
// }
// .tiptap-vuetify-editor__content table {
//   border-collapse: collapse;
//   table-layout: fixed;
//   width: 100%;
//   margin: 0;
//   overflow: hidden;
// }
// .tiptap-vuetify-editor__content table td,
// .tiptap-vuetify-editor__content table th {
//   min-width: 1em;
//   border: 2px solid grey;
//   padding: 3px 5px;
//   vertical-align: top;
//   box-sizing: border-box;
//   position: relative;
// }
// .tiptap-vuetify-editor__content table td > *,
// .tiptap-vuetify-editor__content table th > * {
//   margin-bottom: 0;
// }
// .tiptap-vuetify-editor__content table th {
//   font-weight: 700;
//   text-align: left;
// }
// .tiptap-vuetify-editor__content table .selectedCell:after {
//   z-index: 2;
//   position: absolute;
//   content: "";
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background: rgba(200, 200, 255, 0.4);
//   pointer-events: none;
// }
// .tiptap-vuetify-editor__content table .column-resize-handle {
//   position: absolute;
//   right: -2px;
//   top: 0;
//   bottom: 0;
//   width: 4px;
//   z-index: 20;
//   background-color: #adf;
//   pointer-events: none;
// }
// .tiptap-vuetify-editor__content .tableWrapper {
//   margin: 1em 0;
//   overflow-x: auto;
// }
// .tiptap-vuetify-editor__content .resize-cursor {
//   cursor: col-resize;
// }
// .tiptap-vuetify-editor__content--disabled {
//   color: rgba(0, 0, 0, 0.38);
// }
// .tiptap-vuetify-editor__content--disabled:after {
//   background-color: rgba(0, 0, 0, 0.06);
//   position: absolute;
//   content: "";
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }
// #tiptap-vuetify-image-upload-area__input-file {
//   display: none;
// }
// .tiptap-vuetify-image-upload-area-holder {
//   background-color: #c8dadf;
//   height: 400px;
//   outline: 2px dashed #92b0b3;
//   outline-offset: -10px;
//   transition: all 0.15s ease-in-out;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
// }
// .tiptap-vuetify-image-upload-area-holder--dragover,
// .tiptap-vuetify-image-upload-area-holder:hover {
//   background-color: #a5b7bc;
//   outline: 2px dashed #648083;
//   color: #444;
//   cursor: pointer;
// }
// .tiptap-vuetify-image-upload-area-holder--dragover
//   .tiptap-vuetify-image-upload-area-holder__icon,
// .tiptap-vuetify-image-upload-area-holder:hover
//   .tiptap-vuetify-image-upload-area-holder__icon {
//   fill: #5f777a !important;
// }
// .tiptap-vuetify-image-upload-area-holder
//   .tiptap-vuetify-image-upload-area-holder__icon {
//   width: 100%;
//   height: 80px;
//   fill: #92b0b3;
//   display: block;
//   margin-bottom: 40px;
//   transition: all 0.15s ease-in-out;
// }
// .tiptap-vuetify-todo-item-view {
//   display: flex;
// }
</style>
